
import Vue from "vue";

export default Vue.extend({
  name: "Blog",
  data() {
    return {
      blogBanner: "https://resource.evvvcloud.com/Image/Panorama.jpg",
      list: [
        {
          title: "Title",
          description: "Description",
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
        {
          title: "Title",
          description: "Description",
          url: "https://resource.evvvcloud.com/Image/Panorama.jpg",
        },
        {
          title: "Title",
          description: "Description",
          url: "https://resource.evvvcloud.com/Image/Background.jpg",
        },
        {
          title: "Title",
          description: "Description",
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
        {
          title: "Title",
          description: "Description",
          url: "https://resource.evvvcloud.com/Image/Panorama.jpg",
        },
        {
          title: "Title",
          description: "Description",
          url: "https://resource.evvvcloud.com/Image/Background.jpg",
        },
      ],
      page: 1,
    };
  },
});
