
import Vue from "vue";

export default Vue.extend({
  name: "Catalogue",
  data() {
    return {
      catalogueBanner: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
      categories: [
        { id: 0, name: "All" },
        { id: 1, name: "Cloth" },
        { id: 2, name: "Food" },
        { id: 3, name: "Game" },
      ],
      hideFilter: false,
      categoryCheck: 0,
      page: 1,
      list: [
        {
          id: 0,
          name: "Title",
          description:
            "descriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescriptiondescription",
          discountType: 0,
          discount: 0.9,
          limit: 0,
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
        {
          id: 1,
          name: "Title",
          description: "description",
          discountType: 0,
          discount: 0.9,
          limit: 0,
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
        {
          id: 2,
          name: "Title",
          description: "description",
          discountType: 0,
          discount: 0.9,
          limit: 0,
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
        {
          id: 3,
          name: "Title",
          description: "description",
          discountType: 0,
          discount: 0.9,
          limit: 0,
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
        {
          id: 4,
          name: "Title",
          description: "description",
          discountType: 0,
          discount: 0.9,
          limit: 0,
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
        {
          id: 5,
          name: "Title",
          description: "description",
          discountType: 0,
          discount: 0.9,
          limit: 0,
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
        {
          id: 6,
          name: "Title",
          description: "description",
          discountType: 0,
          discount: 0.9,
          limit: 0,
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
        {
          id: 7,
          name: "Title",
          description: "description",
          discountType: 0,
          discount: 0.9,
          limit: 0,
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
        {
          id: 8,
          name: "Title",
          description: "description",
          discountType: 0,
          discount: 0.9,
          limit: 0,
          url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg",
        },
      ],
    };
  },
});
