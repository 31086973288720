<template>
  <v-container :class="mainClass" fluid>
    <v-row class="fill-height">
      <v-col class="col-12 col-md-5 pa-0">
        <v-btn
          icon
          x-large
          elevation="4"
          style="
            position: absolute;
            top: 15px;
            left: 15px;
            z-index: 10;
            background: rgba(1, 1, 1, 0.2);
            backdrop-filter: blur(5px);
          "
          to="/catalogue"
          ><v-icon color="white">mdi-chevron-left</v-icon></v-btn
        >
        <v-img
          class="fill-height"
          src="https://resource.evvvcloud.com/Image/XRDevelopment.jpg"
        ></v-img>
      </v-col>
      <v-col class="col-12 col-md-6 pa-8">
        <v-container>
          <v-row>
            <v-col style="height: 100px; overflow: hidden">
              <span
                class="font-weight-bold text-md-h4 text-h5"
                style="word-wrap: break-word"
                >TitleTitleTitleTitleTitleTitleTitleTitleTitleTitleTitle</span
              >
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col style="height: 110px; overflow: hidden">
              <span class="font-weight-light" style="word-wrap: break-word"
                >DescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescriptionDescription</span
              >
            </v-col>
          </v-row>
          <v-row class="mt-16">
            <v-col align="right">
              <span class="font-weight-bold text-md-h4 text-h5">10€</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="right">
              <span class="font-weight-bold red--text text-md-h3 text-h4"
                >30% Off</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn large color="red" dark>
                <span class="text-capitalize"> Get Discount </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col class="col-12 col-md-1 pa-3">
        <v-container :class="rightColClass">
          <v-row>
            <v-col class="col-4 col-md-12 pa-0" align="center">
              <v-btn icon
                ><v-icon>{{ leftBtn }}</v-icon></v-btn
              >
            </v-col>
            <v-col class="col-4 col-md-12 pa-0" align="center">
              <v-icon></v-icon>
            </v-col>
            <v-col class="col-4 col-md-12 pa-0" align="center">
              <v-btn icon
                ><v-icon>{{ rightBtn }}</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script type="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Information",
  computed: {
    mainClass() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return ''
        case 'sm': return ''
        case 'md': return 'fill-height py-0'
        case 'lg': return 'fill-height py-0'
        case 'xl': return 'fill-height py-0'
      }
      return '';
    },
    rightColClass() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return ''
        case 'sm': return ''
        case 'md': return 'fill-height'
        case 'lg': return 'fill-height'
        case 'xl': return 'fill-height'
      }
      return '';
    },
    leftBtn() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'mdi-chevron-left'
        case 'sm': return 'mdi-chevron-left'
        case 'md': return 'mdi-chevron-up'
        case 'lg': return 'mdi-chevron-up'
        case 'xl': return 'mdi-chevron-up'
      }
      return '';
    },
    rightBtn() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'mdi-chevron-right'
        case 'sm': return 'mdi-chevron-right'
        case 'md': return 'mdi-chevron-down'
        case 'lg': return 'mdi-chevron-down'
        case 'xl': return 'mdi-chevron-down'
      }
      return '';
    },
  },
});
</script>

<style scoped></style>
