
import Vue from "vue";
export default Vue.extend({
  name: "Home",
  data() {
    return {
      banners: [
        { url: "https://resource.evvvcloud.com/Image/XRDevelopment.jpg" },
        { url: "https://resource.evvvcloud.com/Image/Panorama.jpg" },
        { url: "https://resource.evvvcloud.com/Image/Background.jpg" },
      ],
      carouselClientWidth: 0,
    };
  },
  mounted() {
    this.carouselClientWidth =
      document.getElementById("carousel")?.clientWidth || 0;
  },
  components: {},
  methods: {},
});
