
import Vue from "vue";

export default Vue.extend({
  name: "App",

  data() {
    return {
      drawer: false,
      menus: [
        { title: "home", icon: "mdi-home", link: "/" },
        {
          title: "catalogue",
          icon: "mdi-shopping-outline",
          link: "/catalogue",
        },
        { title: "blog", icon: "mdi-post-outline", link: "/blog" },
      ],
      languageList: [
        { id: "en", text: "English" },
        { id: "de", text: "Deutsch" },
        { id: "cn", text: "简体中文" },
      ],
      media: [
        { icon: "youtube", link: "https://www.youtube.com/" },
        { icon: "facebook", link: "https://www.youtube.com/" },
        { icon: "instagram", link: "https://www.youtube.com/" },
      ],
    };
  },

  methods: {
    ChangeLanguage(language: any) {
      this.$i18n.locale = language;
      localStorage.setItem("lang", language);
      this.$router.go(0);
    },
  },
});
