import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { createPinia, PiniaVuePlugin } from "pinia";
import VueCompositionAPI from "@vue/composition-api";
import VueI18n from "vue-i18n";
import en from "@/components/Language/en.ts";
import de from "@/components/Language/de.ts";
import cn from "@/components/Language/cn.ts";

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

Vue.use(VueI18n);

let lang = "en";
try {
  if (localStorage.getItem("lang")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lang = localStorage.getItem("lang");
  } else {
    localStorage.setItem("lang", lang);
  }
} catch (e) {
  localStorage.setItem("lang", lang);
}
const i18n = new VueI18n({
  locale: lang,
  messages: {
    en: en,
    de: de,
    cn: cn,
  },
});

new Vue({
  router,
  vuetify,
  pinia,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
